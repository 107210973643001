body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    height: 100%;
  }
  
  .side-bar {
    width: 250px;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    z-index: 1;
    position: relative;
  }
  
  .inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  /* @media only screen and (max-width: 678px) {
    .container {
      flex-wrap: wrap;
      flex-direction: column;
    }
  
    .side-bar {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .side-bar__segment--header {
      text-align: center;
    }
  
    .side-bar__segment--bottom {
      position: static !important;
      margin: 10px;
    }
  
    label {
      display: block;
      float: left;
      width: 120px;
    }
  
    .card {
      width: 100%;
      min-width: 120px !important;
      margin: 20px;
    }
  
    .side-bar__segment > label > input[type='number'],
    .side-bar__segment > label > input[type='text'] {
      width: 180px !important;
    }
  }
  
  @media only screen and (max-width: 470px) {
    .margin-top--small .inputStyle {
      width: 2rem !important;
      height: 2rem;
      margin: 0 5px;
      font-size: 1rem;
    }
  
    .card > form > p {
      font-size: 1.2em !important;
    }
  } */
  
  .side-bar__segment {
    padding: 0.5rem 1rem;
  }
  
  .side-bar__segment--header {
    margin: 1rem 0;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
  }
  
  .side-bar__segment--header:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .side-bar__segment--bottom {
    font-weight: bold;
    position: absolute;
    bottom: 2rem;
  }
  
  .view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f2f2f3;
  }
  
  .side-bar__segment > label > input[type='number'],
  .side-bar__segment > label > input[type='text'] {
    width: 150px;
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin: 0.5rem 0;
    height: 1.5rem;
    padding: 0 0.5rem;
  }
  
  .side-bar__segment > label > input[type='checkbox'] {
    border-radius: 4px;
    border: 1px solid lightgrey;
    margin: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
  
  .card {
    min-width: 360px;
    padding: 2rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border-radius: 4px;
    background: white;
  }
  
  .card > form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card > form > p {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .btn-row {
    display: flex;
    flex-direction: row;
  }
  
  .btn {
    background-color: #3273dc;
    border: none;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
  }
  
  .btn:disabled {
    background-color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
  }
  
  .margin-top--small {
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
  
  .margin-top--small > div {
    flex-wrap: wrap;
  }
  
  .margin-top--small > div > div {
    margin-bottom: 1rem;
  }
  
  .margin-top--medium {
    margin-top: 1.5rem;
  }
  
  .margin-top--large {
    margin-top: 4rem;
  }
  
  .error {
    border: 1px solid red !important;
  }
  